.mynav {
  background: #00051a;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-family: "Archivo Black", sans-serif !important;
}
.mynav .navLink {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: "Archivo Black", sans-serif !important;
}
.mynav .navLink p {
  cursor: pointer;
  font-family: "Archivo Black", sans-serif !important;
}

.mynav .logo {
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Archivo Black", sans-serif !important;
}
.mynav .logo img {
  width: 40px;
  border-radius: 50%;
  font-family: "Archivo Black", sans-serif !important;
}

.mynav .icon {
  border: 2px solid #ffccd2;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 3px;
}

/* navber icon for mobile devices media quiry */

@media (max-width: 768px) {
  
  .mynav .icon {
    margin-right: 10px;
    margin-top: 3px;
  }
}

.mynav .icon svg path {
  color: #ffccd2 !important;
}
