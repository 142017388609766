:root {
  --bgGradient: linear-gradient(92.01deg, #aef77e 1.3%, #81f8a6 100%);
  --bgGradient2: linear-gradient(92.01deg, #0e2d25 1.3%, #238243 100%);
  --bgGradient3: linear-gradient(92.01deg, #081f19 1.3%, #0e2d25 100%);
  --bgPrimary: #0e2d25;
  --primaryColor: #acf10e;
}
* {
  font-family: "Lora", serif;
  font-style: italic;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: white !important;
}
p {
  color: white;
  margin-bottom: 0 !important;
}

button {
  border: none;
  outline: none;
  background: var(--bgGradient);
  border-radius: 5px !important;
  padding: 2px 10px;
  color: black;
}

.primary {
  color: var(--primaryColor);
}

.archivoBlackFont {
  font-family: "Archivo Black", sans-serif;
}
