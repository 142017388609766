.hero {
  background-image: url("../../../public/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  text-align: center;
  /* background-color: transparent;
  background-image: linear-gradient(180deg, #405565 0%, #b1617f 100%); */
}

.hero .space {
  font-weight: 700;
  color: var(--primaryColor);
}

.hero .hexagonal {
  max-width: 550px;
  height: 600px;
  margin: auto;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(../../img/hexa-shape.png);
}

.hero .hexagonal .inner {
  flex-direction: column;
  width: 80%;
}

.hero .central div {
  width: 100%;
}

.hero button.burned {
  background: #183d2f;
  color: #73a095;
}

.hero .burned {
  color: var(--primaryColor);
  padding: 8px 15px;
  margin-top: 10px;
}
.hero .didnt {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.hero .didnt button.burned {
  padding: 10px 15px;
  font-size: 13px;
}

.hero .claim {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
}

.hero .claimButton {
  margin: 10px auto !important;
  padding: 7px 20px;
}

.hero .referral-text {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  background: #183d2f;
  color: #fff;
  border: 1px solid #73a095;
  padding: 6px 5px;
  border-radius: 10px;
  margin: 10px;
}
.hero .referral-text::-webkit-scrollbar {
  display: none;
}
.hero .referral-text:focus {
  outline: none;
}
