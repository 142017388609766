.footer {
  background-color: #00051a;
  display: flex;
  justify-content: space-between;
  padding: 5px 70px;
  flex-wrap: wrap;
}
.footer * {
  color: #ffccd2;
}

.footer .icon {
  border: 2px solid #ffccd2;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer .icon svg path {
  color: #ffccd2 !important;
}

.footer .navLink p {
  cursor: pointer;
  color: #ffccd2;
}
.footer-desc h5 {
  color: #ffccd2;
}
